<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center">WaifuFaucet</h1>
      </div>
      <div class="col-md-12 text-center">
        <a href="/bridge">SLP Bridge</a> -
        <a href="/wallet">Wallet</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-outline-primary" @click="setNetwork('bch')">
              SmartBCH
              <img src="@/assets/bitcoin-cash-bch-logo.png" width="16" height="16" />
            </button>
            <button type="button" class="btn btn-outline-primary" @click="setNetwork('avax')">
              Avalanche
              <img src="@/assets/avalanche-avax-logo.png" width="16" height="16" />
            </button>
            <button type="button" class="btn btn-outline-primary" @click="setNetwork('polygon')">
              Polygon
              <img src="@/assets/polygon-matic-logo.png" width="16" height="16" />
            </button>
            <!--button type="button" class="btn btn-outline-primary" @click="setNetwork('ftm')">
              Fantom
              <img src="@/assets/fantom-ftm-logo.png" width="16" height="16" />
            </button-->
            <button type="button" class="btn btn-outline-primary" @click="setNetwork('bnb')">
              Binance Smart Chain
              <img src="@/assets/bnb-bnb-logo.png" width="16" height="16" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!$store.state.metamask.connected">
      <div class="col-md-12">
        <div class="mb-3">
          <div class="card-body">
            <h5 class="card-title">Connect Metamask</h5>
            <button class="metamask-detected btn btn-primary" @click="connect()">Generate my SLPNFT bridge address</button>
            <p v-if="!$store.state.metamask.available">Metamask not found. Please install it!</p>
          </div>
        </div>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<style>
  .row {
    margin: 1em 1em;
  }
</style>

<script>
import netInfo from "../config/networks.json";

export default {
  methods : {
    connect: function() {
      if(typeof window.ethereum !== 'undefined') {
        this.$store.commit('SET_METAMASKAVAILABLE', true);
        window.ethereum.request({ method: 'eth_requestAccounts' }).then(() => {
          this.$store.commit('SET_METAMASKADDRESS', window.ethereum.selectedAddress);
        });
      }
    },
    setNetwork: function(network) {
      let connectedNetwork = null;
      for(var i in netInfo.networks) {
        if(network === netInfo.networks[i].short) {
          connectedNetwork = netInfo.networks[i];
        }
      }
      this.$store.commit('SET_NETWORK', network);
      this.$store.commit('SET_NETWORKOBJECT', connectedNetwork);
    }
  },
  mounted() {
    this.connect();
  }
}
</script>